.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  font-family: 'Futura', sans-serif;
  font-weight: lighter;
  color: #000000;
  text-align: center;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}

.about-images {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
  flex-wrap: nowrap; /* Prevent images from stacking */
}

.about-image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.about-image img {
  max-width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 5px;
}

.about-content {
  line-height: 2;
  max-width: 800px;
  text-align: left;
}

.about-content h3 {
  font-size: 22px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.about-content p {
  font-size: 15px;
  line-height: 1.8;
  margin-bottom: 10px;
}

.about-links {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.about-links a {
  text-decoration: none;
  padding: 5px 10px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid #000000;
  transition: background-color 0.3s ease;
}

.about-links a:hover {
  background-color: #ededed;
}

@media screen and (max-width: 767px) {
  .about-container {
    padding: 10px;
    text-align: center;
  }

  .about-images {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap; /* Ensures images stay side by side */
    gap: 2px; /* Reduced gap for smaller devices */
  }

  .about-image {
    flex: 1;
  }

  .about-image img {
    height: auto;
    max-height: 220px; /* Slightly increased size */
    max-width: 48%; /* Keeps them side by side */
  }

  .about-content {
    text-align: center;
    padding: 0 10px;
  }

  .about-links {
    flex-direction: column;
    align-items: center;
  }

  .about-links a {
    width: 100%;
    margin-bottom: 10px;
  }
}
