.navbar {
  background-color: #dedfd9;
  color: #fff;
  padding: 10px 20px;
  max-width: 100%;
  box-sizing: border-box;
}

.navbar .top-row {
  display: flex;
  justify-content: center;
  font-family: 'Futura', sans-serif; 
  align-items: center; 
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.navbar .top-row .logo {
  margin-right: 5px;
  margin-top: 10px;
  color: #000000; 
  text-decoration: none; 
  cursor: pointer; 
  font-size: 30px;
  text-align: center;
}

.navbar .top-row .subtitle {
  margin-left: 5px;
  margin-top: 10px;
  color: #000000; 
  text-decoration: none; 
  cursor: pointer; 
  font-size: 25px;
  text-align: center;
}

.navbar .bottom-row {
  display: flex;
  justify-content: center;
}

.navbar .bottom-row a {
  margin: 0 40px;
  margin-bottom: 10px;
  color: #9c2a2a;
  text-decoration: none;
  font-family: 'Futura', sans-serif; 
}

@media screen and (max-width: 767px) {
  .navbar {
    padding: 5px 10px;
  }

  .navbar .top-row {
    flex-direction: column;
    align-items: center;
    margin-bottom: 5px;
  }

  .navbar .top-row .logo {
    font-size: 24px;
  }

  .navbar .top-row .subtitle {
    font-size: 20px;
  }

  .navbar .bottom-row {
    flex-direction: column; 
    align-items: center; 
  }

  .navbar .bottom-row a {
    margin: 10px 0; 
  }
}
